<script>

import * as d3 from 'd3'

export default {
  name: 'StateMachine',
  props: {
    states: {
      type: Array,
      default: () => []
    },
    entities: {
      type: Array,
      default: () => []
    },
    offsets: {
      type: Array,
      default: () => []
    },
    dags: {
      type: Array,
      default: () => []
    },
    edges: {
      type: Array,
      default: () => []
    },
    mouseover: {
      type: Function,
      default: () => {}
    },
    mouseout: {
      type: Function,
      default: () => {}
    },
    click: {
      type: Function,
      default: () => {}
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fontSize: 14,
      fontFamily: 'Roboto, sans-serif',
      canvas: null,
      context: null,
      arrowColors: ['#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#5C6BC0', '#42A5F5', '#29B6F6', '#26C6DA', '#26A69A', '#66BB6A', '#9CCC65', '#D4E157', '#FFCA28', '#FFA726', '#FF7043', '#8D6E63', '#BDBDBD', '#78909C'],
      stateColors: [],
      stateTextWidth: 0,
      entityTextWidth: 0,
      offsetsMap: {}
    }
  },
  methods: {
    getMaxTextWidth (arr) {
      let maxWidth = 0
      for (let i = 0; i < arr.length; i += 1) {
        let width = this.getTextWidth(arr[i].name)
        if (maxWidth < width) {
          maxWidth = width
        }
      }
      return Math.ceil(maxWidth)
    },
    getTextWidth (text) {
      return this.context.measureText(text).width
    },
    shuffle (arr) {
      let cnt = arr.length; let temp; let index

      while (cnt > 0) {
        index = Math.floor(Math.random() * cnt)
        cnt--
        temp = arr[cnt]
        arr[cnt] = arr[index]
        arr[index] = temp
      }

      return arr
    },
    setStateColorMap () {
      if (this.colors.length > 0) {
        this.stateColors = this.colors
      } else {
        let indices = this.shuffle(Array.from({ length: this.arrowColors.length }, (v, k) => k))
        let stateColors = []
        for (let i = 0; i < this.states.length; i += 1) {
          stateColors[i] = this.arrowColors[indices[i]]
        }
        this.stateColors = stateColors
      }
    },
    calculateMachineWidth () {
      let maxDepth = 0
      let offset
      if (this.edges.length > 0) {
        for (let i = 0; i < this.edges.length; i += 1) {
          if (maxDepth < this.edges[i][1][2]) {
            maxDepth = this.edges[i][1][2];
          }
        }
      } else {
        for (let i = 0; i < this.dags.length; i += 1) {
          let maxDagDepth = 0;
          for (let j = 0; j < this.dags[i].length; j += 1) {
              if (maxDagDepth < this.dags[i][j][2]) {
                  maxDagDepth = this.dags[i][j][2]
              }
          }
          if (maxDepth < maxDagDepth) {
              maxDepth = maxDagDepth;
          }
        }
      }
      return this.stateTextWidth + (maxDepth + 2) * (this.entityTextWidth + 30)
    },
    renderStateMachine () {
      const width = this.calculateMachineWidth()
      const height = 40 + this.states.length * 80

      this.setStateColorMap()

      const svg = d3
        .select('#state-machine-' + this._uid)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        // .style('background-color', '#F1F3F4')

      const rect = svg
        .selectAll('rec')
        .data(this.states)
        .enter()
        .append('rect')

      const stateBoxes = rect
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('x', 4)
        .attr('y', (d, i) => (i + 1) * 80 - 25)
        .attr('width', this.stateTextWidth + 12)
        .attr('height', 40)
        .style('fill', '#607D8B')

      const text = svg
        .selectAll('text')
        .data(this.states)
        .enter()
        .append('text')

      const states = text
        .attr('x', 10)
        .attr('y', (d, i) => (i + 1) * 80)
        .text(d => d.name)
        .style('fill', 'white')
        .style('font-size', this.fontSize + 'px')
        .style('font-family', this.fontFamily)

      const line = svg
        .selectAll('line')
        .data(this.states)
        .enter()
        .append('line')

      for (let i = 0; i < this.states.length; i += 1) {
        svg
          .append('defs')
          .append('marker')
          .attr('id', 'arrow' + i)
          .attr('viewBox', [0, 0, 20, 20])
          .attr('refX', 10)
          .attr('refY', 10)
          .attr('markerWidth', 5)
          .attr('markerHeight', 5)
          .attr('orient', 'auto-start-reverse')
          .append('path')
          .attr('d', d3.line()([[0, 0], [0, 20], [20, 10]]))
          .attr('fill', this.stateColors[i])
      }

      const stateArrows = line
        .style('stroke', (d, i) => this.stateColors[i])
        .attr('x1', this.stateTextWidth + 20)
        .attr('y1', (d, i) => (i + 1) * 80 - 4)
        .attr('x2', this.stateTextWidth + (width - this.stateTextWidth) - 20)
        .attr('y2', (d, i) => (i + 1) * 80 - 4)
        .attr('stroke-width', 4)
        .attr('marker-end', (d, i) => `url(#arrow${i})`)

      this.renderDags(svg)
    },
    renderDags (svg) {
      if (this.edges.length > 0) {
        for (let i = 0; i < this.edges.length; i += 1) {
          this.renderPortsConnection(svg, this.edges[i][0][0], this.edges[i][1][0], this.edges[i][0][2], this.edges[i][1][2])
        }

        let hits = []
        for (let i = 0; i < this.edges.length; i += 1) {
          let hit = this.edges[i][0][0] + '_' + this.edges[i][0][1] + '_' + this.edges[i][0][2]
          if (!hits.includes(hit)) {
            hits.push(hit)
            this.renderStatePort(svg, this.edges[i][0][0], this.edges[i][0][1], this.edges[i][0][2])
          }

          hit = this.edges[i][1][0] + '_' + this.edges[i][1][1] + '_' + this.edges[i][1][2]
          if (!hits.includes(hit)) {
            hits.push(hit)
            this.renderStatePort(svg, this.edges[i][1][0], this.edges[i][1][1], this.edges[i][1][2])
          }
        }
      } else {
        for (let i = 0; i < this.dags.length; i += 1) {
          for (let j = 0; j < this.dags[i].length; j += 1) {
            if (j > 0) {
              this.renderPortsConnection(svg, this.dags[i][j - 1][0], this.dags[i][j][0], this.dags[i][j - 1][2], this.dags[i][j][2])
            }
          }
        }

        let hits = []
        for (let i = 0; i < this.dags.length; i += 1) {
          for (let j = 0; j < this.dags[i].length; j += 1) {
            let hit = this.dags[i][j][0] + '_' + j
            if (!hits.includes(hit)) {
              hits.push(hit)
              this.renderStatePort(svg, this.dags[i][j][0], this.dags[i][j][1], this.dags[i][j][2])
            }
          }
        }
      }
    },
    renderStatePort (svg, stateId, entityId, pos) {
      let cx = this.stateTextWidth + this.entityTextWidth + pos * (this.entityTextWidth + 30) 
      let cy = (stateId + 1) * 80 - 4

      svg
        .append('circle')
        .attr('id', 'circle-' + stateId + '-' + entityId)
        .attr('cx', cx)
        .attr('cy', cy)
        .attr('r', 12)
        .attr('stroke', this.stateColors[stateId])
        .attr('stroke-width', 4)
        .attr('fill', 'white')

      let path = d3.line()
      let pathData = path([[cx, cy - 34], [cx, cy - 12]])
      svg
        .append('path')
        .attr('d', pathData)
        .attr('stroke', this.stateColors[stateId])
        .attr('stroke-width', 4)
        .style('stroke-dasharray', ('3, 3'))

      let label = this.entities[entityId].name
      let labelWidth = this.getTextWidth(label)

      svg
        .append('rect')
        .attr('id', 'box-' + stateId + '-' + entityId)
        .attr('x', cx - labelWidth / 2 - 4)
        .attr('y', cy - 34 - this.fontSize - 8)
        .attr('width', labelWidth + 8)
        .attr('height', this.fontSize + 8)
        .attr('stroke', '#607D8B')
        .attr('stroke-width', 1)
        .style('fill', 'white')

      svg
        .append('text')
        .attr('id', 'text-' + stateId + '-' + entityId)
        .attr('x', cx - labelWidth / 2)
        .attr('y', cy - 34 - this.fontSize / 2)
        .text(label)
        .style('fill', '#607D8B')
        .style('font-size', this.fontSize + 'px')
        .style('font-family', this.fontFamily)

      this.addInteraction(stateId, entityId)
    },
    addInteraction (stateId, entityId) {
      let types = ['circle', 'box', 'text']
      for (let i = 0; i < 3; i += 1) {
        d3
          .select('#' + types[i] + '-' + stateId + '-' + entityId)
          .on('mouseover', () => {
            this.mouseover(stateId, entityId, d3.event)
          })
          .on('mouseout', () => {
            this.mouseout(stateId, entityId, d3.event)
          })
          .on('click', () => {
            this.click(stateId, entityId)
          })
          .style('cursor', 'pointer')
      }
    },
    renderPortsConnection (svg, prevStateId, curStateId, prevPos, curPos) {
      let x0 = this.stateTextWidth + this.entityTextWidth + prevPos * (this.entityTextWidth + 30)
      let y0 = (prevStateId + 1) * 80 - 4
      let x1 = this.stateTextWidth + this.entityTextWidth + curPos * (this.entityTextWidth + 30)
      let y1 = (curStateId + 1) * 80 - 4

      if (prevStateId != curStateId && prevPos != curPos) {
        // M(cx, yc)
        let xc = (x0 + x1) / 2
        let yc = (y0 + y1) / 2

        // (y - y0) / (y1 - y0) = (x - x0) / (x1 - x0) -> (x1 - x0, y1 - y0) - направляющий вектор для исходной линии
        // Этот же вектор - нормальный для линии, проходящей через (xc, yc) и перпендикулярной исходной

        // y = kx + e
        let k = (x0 - x1) / (y1 - y0)
        let e = (xc * x1 - xc * x0 + yc * y1 - yc * y0) / (y1 - y0)

        // Квадрат расстояния между точками (xc, yc) и искомой точкой (x, y): (x - xc) ^ 2 + (y - yc) ^ 2 = r ^ 2;
        let r = 20 * (curPos - prevPos)
        // Вместо y и y - yc подставляем по формулам kx + b и kxc + b, получаем (x - xc) ^ 2  = r ^ 2 / (1 + k ^ 2)
        // Откуда получаем квадратное уровнение x^2 + bx + c = 0;
        let b = -2 * xc
        let c = Math.pow(xc, 2) - Math.pow(r, 2) / (1 + Math.pow(k, 2))
        let D = Math.pow(b, 2) - 4 * c

        let x_0 = (-b + Math.sqrt(D)) / 2
        let y_0 = k * x_0 + e

        let x_1 = (-b - Math.sqrt(D)) / 2
        let y_1 = k * x_1 + e
        let p = [x_0, y_0]
        if (y_1 > y_0) {
          p = [x_1, y_1]
        }

        let arcPath = d3
          .line()
          .curve(d3.curveBasis)

        let pathData = arcPath([[x0, y0], p, [x1, y1]])

        svg
          .append('path')
          .attr('d', pathData)
          .attr('stroke', this.stateColors[prevStateId])
          .attr('stroke-width', 4)
          .attr('fill', 'none')

      } else if (prevPos == curPos) {
        let arcPath = d3
          .line()

        let pathData = arcPath([[x0, y0], [x1, y1]])

        svg
          .append('path')
          .attr('d', pathData)
          .attr('stroke', this.stateColors[prevStateId])
          .attr('stroke-width', 4)
          .attr('fill', 'none')
      }
    }
  },
  mounted () {
    this.canvas = document.createElement('canvas')
    this.context = this.canvas.getContext('2d')
    this.context.font = this.fontSize + 'px ' + this.fontFamily
    this.stateTextWidth = this.getMaxTextWidth(this.states)
    this.entityTextWidth = this.getMaxTextWidth(this.entities)
    this.renderStateMachine()
  },
  render (createElement) {
    return createElement('div', {
      class: {
        'state-machine': true
      },
      attrs: {
        id: 'state-machine-' + this._uid
      }
    }, [])
  }
}
</script>
